import { render, staticRenderFns } from "./EditCampaign.vue?vue&type=template&id=5daa3fdc"
import script from "./EditCampaign.vue?vue&type=script&lang=js"
export * from "./EditCampaign.vue?vue&type=script&lang=js"
import style0 from "./EditCampaign.vue?vue&type=style&index=0&id=5daa3fdc&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QBreadcrumbs from 'quasar/src/components/breadcrumbs/QBreadcrumbs.js';
import QBreadcrumbsEl from 'quasar/src/components/breadcrumbs/QBreadcrumbsEl.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QRouteTab from 'quasar/src/components/tabs/QRouteTab.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QFile from 'quasar/src/components/file/QFile.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QRadio from 'quasar/src/components/radio/QRadio.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QToolbar,QToolbarTitle,QBreadcrumbs,QBreadcrumbsEl,QSeparator,QSpace,QBtn,QForm,QCard,QTabs,QRouteTab,QTabPanels,QTabPanel,QCardSection,QCheckbox,QSelect,QInput,QImg,QAvatar,QFile,QIcon,QTooltip,QRadio,QCardActions});
